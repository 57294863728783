export const District=[
    "Choose...",
  "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ];
    
    export const BG =[
      "Choose...",
      "A+",
      "A-",
      "B+",
      "B-",
      "O+",
      "O-",
      "AB+",
      "AB-"
    ];
  
    export const Gender =[
      "Choose...",
      "Male",
      "Female",
      "Other"
    ];
  
  
    export const Departments =[
      "Choose...",
      "Marketing",
      "Web Development",
      "Testing",
      "Academy"
    ];
  
    export const EmployeeType =[
      "Choose...",
      "Permanent",
      "Temporary",
      "Intern"
    ];
  
    export const DutyType =[
      "Choose...",
      "Full Time",
      "Part Time"
    ];
  
    export const ShiftType=[
      "Choose...",
      "Morning",
      "Afternoon",
      "Evening",
      "Night",
      "General"
    ]
  
    export const ManagementErrors = {
      requiredField: '"This field is required."',
      invalidFormat: '"The input format is invalid."',
      minLength: '"The input is too short."',
      maxLength: '"The input is too long."',
      email: '"The email address is invalid."',
      passwordMismatch: '"Passwords do not match."',
      invalidCredentials: '"The username or password is incorrect."',
      accountLocked: '"The account is locked due to multiple failed login attempts."',
      tokenExpired: '"The session token has expired."',
      unauthorizedAccess: '"You do not have permission to access this resource."',
      connectionFailed: '"Failed to connect to the database."',
      queryFailed: '"Database query failed."',
      recordNotFound: '"The requested record was not found."',
      duplicateRecord: '"A record with the same identifier already exists."',
      timeout: '"The request timed out."',
      offline: '"The system is currently offline."',
      serverUnavailable: '"The server is currently unavailable."',
      requestFailed: '"The request failed due to a network error."',
      unexpectedError: '"An unexpected error occurred."',
      featureUnavailable: '"This feature is currently unavailable."',
      dataProcessingError: '"An error occurred while processing the data."',
      configurationError: '"There is a configuration error in the system."',
      userNotFound: '"User not found."',
      accessDenied: '"User does not have the necessary permissions."',
      profileIncomplete: '"User profile is incomplete."',
      actionNotAllowed: '"User action is not allowed."'
    };